import React, {useContext} from "react";
import { TagContext} from "../context/tagContext";

const Tag = props => {

    const { tags, setTags } = useContext(TagContext);

    const fontWeight = (props.type === "Attribute")? "font-extrabold" : "font-medium"
    const backgroundColor = (props.type === "Attribute")? "bg-cyan-900" : "bg-gray-200"
    const fontColor = (props.type === "Attribute")? "text-white" : "text-gray-700"
    const margins = (props.type === "Attribute")? "ml-2 mt-2" : "mr-2 mb-2"
    //const text = (props.type === "Publisher" || props.type === "Designer" || props.type === "Artist") ? props.type + ": " + props.name : props.name

    const tagClicked = (event) => {
        console.log("TAG CLICKED " + props.name + "- " + props.type + event)
        event.stopPropagation()
        //event.nativeEvent.stopImmediatePropagation()
        console.log("Stopping propegation")
        const matchingFilters = tags.filter(f => f.name === props.name)
        if (matchingFilters.length === 0) {
            setTags([...tags, props])
        }else{
            console.log("Not adding filter.  ALready exists?" + JSON.stringify(matchingFilters))
        }

    }
    return (
        <span className={`inline-block rounded-full px-3 py-1 text-xs cursor-pointer ${fontWeight} ${backgroundColor} ${fontColor} ${margins}`} onClick={tagClicked}>
            {props.name}
        </span>
    )
}

export default Tag