import React from "react";
import { CheckIcon } from '@heroicons/react/solid'
import { XIcon } from '@heroicons/react/solid'

const ConfirmationModal = props =>{


    const {show, body, onConfirm, onCancel} = props;

    if (!show){
        return null;
    }

    return (
        <div id="popup-modal" className="overflow-y-auto overflow-x-hidden fixed top-0 right-0 bottom-0  bg-gray-700/90 left-0 z-50 md:inset-0 h-modal md:h-full flex items-center justify-center" onClick={onCancel}>
            <div class="relative p-4 w-full max-w-md h-full md:h-auto" onClick={e => e.stopPropagation()}>
                <div class="relative bg-white rounded-lg shadow dark:bg-gray-700">                    
                    <div class="p-6 text-center">
                        <svg class="mx-auto mb-4 w-14 h-14 text-gray-400 dark:text-gray-200" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path></svg>
                        <h3 class="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">{body}</h3>
                        <button data-modal-toggle="popup-modal" type="button" class="text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-gray-200 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600 mr-3" onClick={onConfirm}>
                            <div className="flex flex-columns"><CheckIcon className="w-5 h-5 mr-1" /> Yes </div>
                        </button>
                        <button data-modal-toggle="popup-modal" type="button" class="text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-gray-200 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600" onClick={onCancel}>
                        <div className="flex flex-columns"><XIcon className="w-5 h-5 mr-1" /> No</div>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ConfirmationModal