import axios from "axios";
export const BACKEND_URL_GAME = process.env.REACT_APP_BACKEND_URL + "/game";
export const BACKEND_URL_EXPANSION = process.env.REACT_APP_BACKEND_URL + "/expansion";

class GameService{

    deleteGame(gameId){
        axios.delete(`${BACKEND_URL_GAME}/${gameId}`)
            .then(res => {
                console.log("Remove Game Response:", res.data);
            })
    }

    async refreshMetadata(id) {
        try{
            const res = await axios.get(`${BACKEND_URL_GAME}/${id}`,{ params: { refresh_metadata: true }}  )
            if (res.data){
                return res.data;
            }
        }catch(err){
            console.log("Error fetching game:", err)
            throw err
        }
        return null;
    }

    async fetchGame(gameId){
        return await this._fetchGameOrExpansion( BACKEND_URL_GAME, gameId);      
    }

    async fetchExpansion(gameId){
        return await this._fetchGameOrExpansion(BACKEND_URL_EXPANSION, gameId);
    }

    async addGame(game){
        try{
            const res = await axios.post(`${BACKEND_URL_GAME}/`,  game )
            if (res.data){
                return res.data;
            }
        }catch(err){
            console.log("Error fetching game:", err)
            throw err
        }
        return null;
    }        
        

    async _fetchGameOrExpansion( url, gameId){
        try{
            const res = await axios.get(`${url}/${gameId}`)
            if (res.data) {
                return res.data
            }
        }catch(err){
            console.log("Error fetching game:", err)
            throw err
        }
        return null
    }
} 

export default new GameService();