import React, { createContext, useState } from "react";
import axios from "axios"
import GameService from "../services/game.service";

export const GameContext = createContext();
export const BACKEND_URL = process.env.REACT_APP_BACKEND_URL
const BACKEND_URL_GAME = BACKEND_URL + "/game"
const BACKEND_URL_EXPANSION = BACKEND_URL + "/expansion"
const GameProvider = ({children}) => {

    const [games, _setGames] = useState([])
    const [activeGame, setActiveGame] =  useState({})
    const [loading, setLoading] = useState(false)
    const [metadataUpdateProgress, setMetadataUpdateProgress] = useState({})

    //console.log("REBUILDING PROVIDER STATE")

    const removeGame = id => {
        console.log("REMOVE GAME", "removing game: " + id)
        GameService.deleteGame(id)
        
    }

    const refreshMetadata = (id) => {
        console.log(`Updating Metadata for ${id}`)
        setLoading(true)
        GameService.refreshMetadata(id)
            .then(game => {
                //console.log("Updating Metadata Response:", res);
                setActiveGame(game)
                //console.log("Updating Metadata: ", activeGame)                
            })
            .finally(() => {
                setLoading(false)
            })
    };

    const refreshAllMetadata = async () => {
        console.log("Updating ALL Metadata")
        let percentageComplete = 0
        let index = 1
        for (const game  of games) {                    
            percentageComplete = parseInt(index / games.length * 100)
            setMetadataUpdateProgress({"currentGameName": game.name, "percentageComplete": percentageComplete, "totalGames": games.length, "currentGameIndex": index})
            console.log(`Refreshing metadata for ${game.name} (${index})`)
            try{
                GameService.refreshMetadata(game.bgg_id)          
            }catch(err){
                console.log(`reveived error refreshing data for ${game.name} (index ${index}).  sleeping 30 seconds and continuting `,  err)                
                await sleep(30000)
                console.log(`Refreshing metadata for ${game.name} (index ${index})`)
                try{
                    await axios.get(`${BACKEND_URL_GAME}/${game.bgg_id}`,{ params: { refresh_metadata: true }}  )                    
                }catch(err){
                    console.log(`Received 2 errors refreshing data.  Stopping at index ${index}`, err)
                    return
                }
            } 
            index++
            await sleep(5000)           
        }
        setMetadataUpdateProgress({})
    }


    const fetchGame = (id) => {
        setLoading(true)
        GameService.fetchGame(id)
            .then(game => {
                console.log("FETCH GAME RESPONSE:", game)
                setActiveGame(game)                
            })
            .finally(() => {
                setLoading(false)
            });
    };

    const fetchExpansion = (expansion_id) => {
        setLoading(true)
        GameService.fetchExpansion(expansion_id)
            .then(expansion => {
                //console.log("Fetch Game Response:", res);
                setActiveGame(expansion)
                //console.log("Active Game: ", activeGame)
            })
            .finally(() => {
                setLoading(false)
            });
    };

    const addGame = game => {
        console.log("GameContext", `Adding game ${JSON.stringify(game)}`)
        GameService.addGame(game)
            .then(res => {
                //console.log("Add Game Response:", res);
                _setGames([...games, res.data]);
            })
    };

    const  refreshGames = () => {
        setLoading(true)
        axios.get(`${BACKEND_URL_GAME}/`)
            .then(res => {
                //console.log("Get All Games Response:", res);
                //console.log(`Refreshed games from ${games.length} to ${res.data.length}`)
                _setGames(res.data)
            })
            .finally(() => {
                setLoading(false)
            })   
    };

    const toggledPlayed = (id) => {
        console.log("Mark Game as Played", id)
        axios.put(`${BACKEND_URL_GAME}/${id}`, {...activeGame, played: !activeGame.played })
            .then(res => {
                //console.log("Mark Game as Played Response:", res);
                setActiveGame(res.data)
                //console.log("Active Game: ", activeGame)
            })
    };

    const toggledOwned = (expansion_id) => {
        console.log("Mark Expansion as Owned", expansion_id)
        axios.put(`${BACKEND_URL_EXPANSION}/${expansion_id}`, {...activeGame, owned: !activeGame.owned })
            .then(res => {
                //console.log("Mark Game as Played Response:", res);
                setActiveGame(res.data)
                //console.log("Active Game: ", activeGame)
            })
    };


    const sleep = ms => new Promise(
        resolve => setTimeout(resolve, ms)
      );

    return (
        <GameContext.Provider value={{ games, activeGame, addGame, refreshGames, removeGame, fetchGame, fetchExpansion, refreshMetadata, refreshAllMetadata, toggledPlayed, toggledOwned, metadataUpdateProgress, loading}}>
            {children}
        </GameContext.Provider>
    );
}

export default GameProvider;
