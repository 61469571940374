
import { Fragment } from 'react'
import { Menu, Transition } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/solid'

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function Dropdown(props) {
    const {selectedOption, onOptionChange , availableOptions, prefix} = props
    
    return (
    <Menu as="div" className="relative inline-block text-left">
      <div>
        <Menu.Button className="dropdown-toggle px-6 py-2 bg-cyan-600 text-gray-200 font-medium text-xs leading-tight uppercase rounded shadow-md transition duration-150 ease-in-out flex items-center whitespace-nowrap" >{prefix} {selectedOption.label}
          <ChevronDownIcon className="-mr-1 ml-2 h-5 w-5" aria-hidden="true" />
        </Menu.Button>
      </div>

{/*
hover:bg-blue-700 hover:shadow-lg
          focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0
          active:bg-blue-800 active:shadow-lg active:text-white
          */    }
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items key="yoo" className="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-30">
          <div className="py-1" key="yo">
            {availableOptions.map((option, index) => (                    
                <Menu.Item key={index}>
                    { ({ active }) => (
                        // eslint-disable-next-line jsx-a11y/anchor-is-valid
                        <a onClick={ ()=>{ onOptionChange(option)} } className={classNames(  active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',  'block px-4 py-2 text-sm' )} >{option.label}</a>
                    )}
                </Menu.Item>
            ))}            
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
    
  )
}