import React from "react";
import Tag from "./Tag";
import {useNavigate} from "react-router-dom";
import {decode} from "html-entities";
import { BadgeCheckIcon } from "@heroicons/react/solid";

const GameCard = props => {
    let navigate = useNavigate();

    const onCardClick = (e, id) => {
        navigate(`/details/${id}`);
    }

    return (
            <div className="p-5" >
                <div className="max-w-sm rounded overflow-hidden shadow-lg">                    
                        <div style={{
                            backgroundImage: `url("${props.image}")`,
                            backgroundSize: 'cover',
                            height: 250
                        }} className="relative cursor-pointer" 
                        onClick={ (e) => onCardClick(e, props.bgg_id) }>
                            {props.is_campaign &&
                                <Tag name="Campaign" type="Attribute" key="is_campaign">Campaign</Tag>
                            }
                            {props.is_legacy &&
                                <Tag name="Legacy" type="Attribute" key="is_legacy">Legacy</Tag>
                            }
                            {props.is_cooperative &&
                                <Tag name="Cooperative" type="Attribute" key="is_cooperative">Cooperative</Tag>
                            }
                            {props.is_18xx &&
                                <Tag name="18XX" type="Attribute" key="is_18xx">18XX</Tag>
                            }
                            
                            {props.played &&

                        <div>
                            <BadgeCheckIcon className="text-white h-20 w-20 absolute top-0 right-0"/>
                        </div>
                        }
                        </div>                        
                        <div className="px-6 pt-4">
                            <div className="font-bold text-l font-poppins">{props.name}</div>
                        </div>

                        <div className="px-6 pb-4">
                            <p className="text-gray-700 text-sm leading-6 flex items-center">
                                {props.year_published}
                                <svg width="3" height="3" fill="currentColor" className="mx-2 text-black" aria-hidden="true">
                                    <circle cx="1" cy="1" r="3"/>
                                </svg>
                                {props.min_players}-{props.max_players}
                                <svg width="3" height="3" fill="currentColor" className="mx-2 text-black" aria-hidden="true">
                                    <circle cx="1" cy="1" r="3"/>
                                </svg>
                                {props.playtime} min
                                <svg width="16" height="20" fill="currentColor" className="ml-2 mr-1 text-black" aria-hidden="true">
                                    <path
                                        d="M7.05 3.691c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.372 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.539 1.118l-2.8-2.034a1 1 0 00-1.176 0l-2.8 2.034c-.783.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.363-1.118L.98 9.483c-.784-.57-.381-1.81.587-1.81H5.03a1 1 0 00.95-.69L7.05 3.69z"/>
                                </svg>
                                {Math.round(props.rating_avg * 100) / 100 }
                                <svg role="img" width="16" height="20" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" className={'ml-2'}><title>BoardGameGeek</title><path d="m19.7 4.44-2.38.64L19.65 0 4.53 5.56l.83 6.67-1.4 1.34L8.12 24l8.85-3.26 3.07-7.22-1.32-1.27.98-7.81Z"/></svg>
            	    			{Math.round(props.rating_bavg * 100) / 100}
                                <svg version="1.1" id="Layer_1" x="0px" y="0px" height="20" viewBox="0 0 375 375"   className="ml-2 mr-1">
                                    <path id="XMLID_511_" d="M360,172.5h-15v-55c0-3.979-1.58-7.794-4.394-10.607c-2.813-2.813-6.628-4.393-10.606-4.393h-70c-8.284,0-15,6.716-15,15v55H135v-55c0-8.284-6.717-15-15-15H50c-3.979,0-7.793,1.58-10.606,4.393C36.58,109.706,35,113.521,35,117.5v55H15c-8.284,0-15,6.715-15,15c0,8.283,6.716,15,15,15h20v55c0,8.283,6.716,15,15,15h70c3.978,0,7.793-1.58,10.606-4.393c2.813-2.813,4.394-6.629,4.394-10.607v-55h110v55c0,3.978,1.58,7.794,4.394,10.607c2.813,2.813,6.628,4.393,10.606,4.393h70c8.283,0,15-6.717,15-15v-55h15c8.283,0,15-6.717,15-15C375,179.215,368.283,172.5,360,172.5z"/>
                                </svg>
                                {Math.round(props.weight_avg * 100) / 100 }
                            </p>
                        </div>
                        <div className="px-6">
                            <p className="text-gray-700 text-base" dangerouslySetInnerHTML={{__html: `${decode(props.tagline)}`}}></p>
                        </div>


                    <div className="px-6 pt-4 pb-2">
                        <Tag name={props.publisher} type="Publisher" key={props.publisher}  />
                        <Tag name={props.designer} type="Designer" key={props.designer}  />
                        {props.mechanics.map(str => <Tag name={str} type="Mechanic" key={str} className="cursor-pointer" />)}
                        {   props.families?.filter(family => family.startsWith("Mechanism: ")).map(str => <Tag name={str.substring(10)} key={str} type="mechanism" />)}                        
                    </div>

                </div>
            </div>
    );
}

export default GameCard
