import React, {useEffect, useContext, useState, useCallback} from "react";
import { GameContext } from "../context/gameContext";
import GameCard from "./GameCard";
import { ArrowNarrowDownIcon, ArrowNarrowUpIcon, XIcon } from '@heroicons/react/solid'
import { TagContext } from "../context/tagContext";
import { RadioGroup } from '@headlessui/react'
import Dropdown from "./Dropdown";
import Spinner from "./Spinner";
import {useSearchParams} from "react-router-dom";

const GameCardList = (props) => {

    const sortOptions = [
        { value: 'rating_avg', label: 'Average Rating' },
        { value: 'rating_bavg', label: 'BGG Rating' },
        { value: 'name', label: 'Name'},
        { value: 'year_published', label: 'Published'},
        { value: 'weight_avg', label: 'Weight' },
        { value: 'playtime', label: 'Playtime' },
        { value: 'create_time', label: 'Date Added' },
        { value: 'update_time', label: 'Date Updated' },
        { value: 'rating_votes', label: 'Vote Count' }

    ]

    const playerCountOptions = [
        { value: 'all', label: 'All' },
        { value: '1', label: '1' },
        { value: '2', label: '2' },
        { value: '3', label: '3' },
        { value: '4', label: '4' },
        { value: '5', label: '5' },
        { value: '6', label: '6' },
        { value: '7', label: '7+' },
    ]

    const gameTypeOptions = [
        { value: 'all', label: 'All' },
        { value: '18XX', label: '18XX' },
        { value: 'Campaign', label: 'Campaign' },
        { value: 'Cooperative', label: 'Cooperative' },
        { value: 'Legacy', label: 'Legacy' },        
    ]

    const [searchParams, setSearchParams] = useSearchParams();
    const { tags } = useContext(TagContext);
    const { games, loading } = props
    const [ filter, setFilter ] = useState('');
    const [ filteredGames, setFilteredGames] = useState()
    const [playedFilter, setPlayedFilter] = useState('all');

    const [ playerCount, setPlayerCount] = useState(playerCountOptions[0])
    const [ gameType, setGameType] = useState(gameTypeOptions[0])

    const [ sort, setSort] = useState(sortOptions[0])
    const [ sortAscending, setSortAscending] = useState(true)
    const [ sortedGames, setSortedGames] = useState()

    

    const onSortChange = (newSortValue) => {
        if (newSortValue.value === sort.value){
            setSortAscending(!sortAscending)
        }else{
            setSortAscending(true)
        }
        setSort(newSortValue)
    }

    const onPlayerCountChange = (newPlayerCountValue) => {
        console.log(newPlayerCountValue)
        setPlayerCount(newPlayerCountValue)
    }

    const onGameTypeChange = (newGameTypeValue) => {
        setGameType(newGameTypeValue)
    }



    const filterGames = useCallback( () => {
        let filtered = false;

        if (sortedGames?.length > 0){
            let gameCopy = [].concat(sortedGames)
            
            //Filter based on played status
            if (playedFilter !== 'all') {
                gameCopy = gameCopy.filter( (g) => {
                    return (playedFilter === 'played')? g.played : !g.played
                })
                filtered = true
            }

            //filter based on tags
            if (tags !== undefined && tags.length > 0 ) {
                tags.forEach( (tag) => {
                    gameCopy = gameCopy.filter( (g) => {     
                        if(tag.type === 'Attribute'){
                            switch(tag.name){
                                case "18XX":
                                    return g.is_18xx
                                case "Campaign": 
                                    return g.is_campaign
                                case "Legacy":
                                    return g.is_legacy
                                case "Cooperative":
                                    return g.is_cooperative
                                default:
                                    return false
                            }
                        }else{               
                            return ( g.families?.includes(tag.name) ||g.mechanics?.includes(tag.name) || g.categories?.includes(tag.name) || g.designer === tag.name || g.publisher === tag.name )                    
                        }
                    })
                });
                filtered = true
            }

            //Filter based on player count
            if (playerCount.value !== 'all') {                
                gameCopy = gameCopy.filter( (g) => {                
                    return g.min_players <= parseInt(playerCount.value) && g.max_players >= parseInt(playerCount.value)
                });
                filtered = true
            }

            //Filter based on game type
            if (gameType.value !== 'all') {
                gameCopy = gameCopy.filter( (g) => {
                    switch(gameType.value){
                        case "18XX":
                            return g.is_18xx
                        case "Campaign": 
                            return g.is_campaign
                        case "Legacy":
                            return g.is_legacy
                        case "Cooperative":
                            return g.is_cooperative
                        default:
                            return false
                    }
                });
                filtered = true
            }

            //Filter based on player count
            if (playerCount.value !== 'all') {                
                gameCopy = gameCopy.filter( (g) => {
                    if (playerCount.value === '7+'){
                    }else{
                        return g.min_players <= parseInt(playerCount.value) && g.max_players >= parseInt(playerCount.value)
                    }
                });
                filtered = true
            }
            
            // Filter based on string entered in filter box
            if (filter !== undefined && filter !== '') {
                gameCopy = gameCopy.filter( (g) => {
                    return g.name.toLowerCase().includes(filter.toLowerCase())
                })            
                filtered = true
            }

            setFilteredGames((filtered)? gameCopy : undefined)
        }else{
            setFilteredGames(undefined)
        }
        
    }, [filter, tags, sortedGames, playedFilter, playerCount, gameType]);


    useEffect( () =>{
        filterGames()
        }, [filterGames, filter]);


    useEffect( () => {
        const updatedSortedGames = [].concat(games).sort( (a, b) => {

            if (sortAscending) {
                if (sort.value !== "name"){
                    return (b[sort.value] > a[sort.value])? 1 : -1                
                }else{
                    return (b[sort.value] < a[sort.value])? 1 : -1                
                }
            }else{
                if (sort.value !== "name"){
                    return (b[sort.value] < a[sort.value])? 1 : -1
                }else{
                    return (b[sort.value] > a[sort.value])? 1 : -1
                }
            }
        } )

        setSortedGames(updatedSortedGames)
        }, [sort, games, sortAscending]);

    useEffect( () =>{

        const gameType = searchParams.get("gameType")
        console.log("gameType: ", gameType);
        if (gameType != null && gameType !== undefined){
            const matchedGameType = gameTypeOptions.find(element => element.value.toLowerCase() === gameType.toLowerCase())
            if (matchedGameType !== undefined){
                setGameType(matchedGameType);
            }
        }

        const playerCount = searchParams.get("playerCount")
        console.log("playerCount: ", playerCount);
        if (playerCount != null && playerCount !== undefined){
            const matchedPlayerCount = playerCountOptions.find(element => element.value === playerCount)
            if (matchedPlayerCount !== undefined){
                setPlayerCount(matchedPlayerCount);
            }

        }
        const sortBy = searchParams.get("sortBy")
        console.log("sortBy: ", sortBy);
        if (sortBy != null && sortBy !== undefined){
            const matchedSortBy = sortOptions.find(element => element.value.toLowerCase() === sortBy.toLowerCase())
            if (matchedSortBy !== undefined){
                setSort(matchedSortBy);
            }

        }
        const sortDirection = searchParams.get("sortDirection")
        console.log("sortDirection: ", sortDirection);
        if (sortDirection != null && sortDirection !== undefined){
            if (sortDirection.toLowerCase() === 'asc'){
                setSortAscending(true);
            }else if (sortDirection.toLowerCase() === 'desc'){
                setSortAscending(false);
            }
        }

        const playedFilter = searchParams.get("playedFilter")
        console.log("playedFilter: ", playedFilter);
        if (playedFilter != null && playedFilter !== undefined){
            const lowerPlayedFilter = playedFilter.toLowerCase()
            if (lowerPlayedFilter === 'all' || lowerPlayedFilter === 'played' || lowerPlayedFilter === 'not_played'){
                setPlayedFilter(playedFilter);
            }
        }
        


    }, []);

        /*
        useEffect( () => {
            console.log("dependecy changed " )
            if (filter !== undefined && filter !== '' && sortedGames !== undefined){
                const fg = [].concat(sortedGames).filter( (g) => {
                    return g.name.includes(filter)
                })              
                setFilteredGames(fg)
            }else{
                setFilteredGames(undefined)
            }

        }, [filter, sortedGames]);
        */
        
    return (
        <>
            <form> 
                <div className="flex pl-5 pt-8">
                    <div className="flex-none min-w-min pt-1" role="group">
                        <RadioGroup value={playedFilter} onChange={setPlayedFilter}>
                            <RadioGroup.Label className="sr-only w-96">Played Status</RadioGroup.Label>
                            <RadioGroup.Option key="all" value="all" className={"rounded-l px-6 py-2 border-2 border-cyan-600 font-medium text-xs leading-tight uppercase hover:bg-black hover:bg-opacity-5 focus:outline-none focus:ring-0 transition duration-150 ease-in-out float-left cursor-pointer " + (playedFilter === 'all' ? 'bg-cyan-600 text-white  hover:text-cyan-600' :'text-cyan-600')}>All</RadioGroup.Option>
                            <RadioGroup.Option key="played" value="played" className={"px-6 py-2 border-t-2 border-b-2 border-cyan-600  font-medium text-xs leading-tight uppercase hover:bg-black hover:bg-opacity-5 focus:outline-none focus:ring-0 transition duration-150 ease-in-out float-left cursor-pointer " + (playedFilter === 'played' ? 'bg-cyan-600 text-white  hover:text-cyan-600'  :'text-cyan-600')}>Played</RadioGroup.Option>
                            <RadioGroup.Option key="not_played" value="not_played" className={"rounded-r px-6 py-2 border-2 border-cyan-600 font-medium text-xs leading-tight uppercase hover:bg-black hover:bg-opacity-5 focus:outline-none focus:ring-0 transition duration-150 ease-in-out float-left cursor-pointer " + (playedFilter === 'not_played'?'bg-cyan-600 text-white hover:text-cyan-600' :'text-cyan-600') }>Not Played</RadioGroup.Option>
                        </RadioGroup>
                    </div>

                    <div className="pt-1 ml-5 flex-none  h-10  " >
                        <div className="flex justify-center">
                            <Dropdown selectedOption={gameType} onOptionChange={onGameTypeChange} availableOptions={gameTypeOptions} prefix="Game Type: " className="h-30"/>
                        </div>
                    </div>

                    <div className="pt-1 ml-5 flex-none  h-10  " >
                        <div className="flex justify-center">
                            <Dropdown selectedOption={playerCount} onOptionChange={onPlayerCountChange} availableOptions={playerCountOptions} prefix="Players: " className="h-30"/>
                        </div>
                    </div>

                    <div className="pt-1 ml-5 flex-none  m-auto  h-10  " >
                        <div className="flex justify-center">
                            <Dropdown selectedOption={sort} onOptionChange={onSortChange} availableOptions={sortOptions} prefix="Sort By" className="h-30"/>
                            {sortAscending?<ArrowNarrowDownIcon onClick={()=>setSortAscending(false)} className="h-10 w-6 text-cyan-600 cursor-pointer"/> : <ArrowNarrowUpIcon onClick={()=>setSortAscending(true)} className="h-10 w-6 text-cyan-600 cursor-pointer"/>}
                        </div>
                    </div>
                    <div className="ml-5 flex-none  m-auto  h-10 pt-4 text-cyan-600" >
                        <div className="flex justify-center font-medium text-xs leading-tight uppercase ">Showing {filteredGames? filteredGames.length : games.length} of {games.length} games</div>
                    </div>


                    <div className="ml-5 pt-1 flex-none min-w-min pr-2 relative" >
                        <input type="text" value={filter} onChange={(e) => setFilter(e.target.value)} placeholder="FILTER" className="rounded-l px-6 py-2 border-2 border-cyan-600 font-medium text-xs  w-96"/>
                        {filter && <XIcon className='h-5 pt-0.5 w-5 text-cyan-600 absolute top-1/2 transform -translate-y-1/2 right-3' onClick={()=>setFilter('')}/>}
                    </div>                
                    
                </div>

                
            </form>
            {
            loading ? 
                    <div className="text-center m-10">
                        <Spinner/>
                    </div>
            :
            (sortedGames !== undefined &&  sortedGames.length > 0 ) ?
                <div className=" grid grid-cols-1 xnarrow:grid-cols-2 narrow:grid-cols-3 wide:grid-cols-4 xwide:grid-cols-5 xxwide:grid-cols-6  xxxwide:grid-cols-7 xxxxwide:grid-cols-8 gap-0">
                {
                    (filteredGames === undefined) 
                    ? sortedGames?.map(summary => <GameCard {...summary} key={summary.bgg_id}/>)
                    : filteredGames.map(summary => <GameCard {...summary} key={summary.bgg_id}/>)
                }
                </div>
            :
                <div className="flex p-10 font-xl">No games found</div>
            }
        </>
    )
}

const GameLibrary = (props) => {

    const { games, refreshGames, loading } = useContext(GameContext);

    useEffect(() => {
        (async () => {
            refreshGames();
        })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
            <GameCardList games={games} loading={loading} />
    )
}

export default GameLibrary