const Login = () =>{
    return(
    <div className="flex flex-col items-center pt-5">
      <h1>Please Log In</h1>
        <form>
            <label>
            <p>Username</p>
            <input type="text" className="border"/>
            </label>
            <label>
            <p>Password</p>
            <input type="password" className="border"/>
            </label>
            <div>
            <button type="submit" className="border">Submit</button>
            </div>
        </form>
        </div>
    )
}
export default Login