import './App.css';
import BGGSearch from "./components/BGGSearch";
import GameLibrary from "./components/GameLibrary";
import GameProvider from "./context/gameContext";
import Navbar from "./components/Navbar";
import Filters from "./components/Filters";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import GameDetails from "./components/GameDetails";
import TagProvider from "./context/tagContext";
import ConfirmationModal from './components/ConfirmationModal';
import Login from './components/Login';


function App() {

    return (
        <BrowserRouter>
            <GameProvider>
                <TagProvider>
                    <Navbar />

                    <Routes>
                        <Route path="/" element={
                            <div >
                                <Filters />
                                <GameLibrary />
                            </div>
                        } />
                        <Route path="add" element={<BGGSearch />} />
                        <Route path="/details/:bgg_id" element={<GameDetails />} />
                        <Route path="login" element={<Login />} />
                    </Routes>
                    < ConfirmationModal />
                </TagProvider>
            </GameProvider>
        </BrowserRouter>
    );
}


export default App;
