import axios from "axios";
import React, {useCallback, useContext, useEffect, useRef, useState} from "react";
import {GameContext, BACKEND_URL} from "../context/gameContext";
import { useNavigate } from "react-router-dom";
import Spinner from "./Spinner";

const BGGSearch = (props) => {

    const [gameSummaries, setGameSummaries] = useState([]);
    const [searchString, setSearchString] = useState("")
    const [loading, setLoading] = useState(false);
    const nameField = useRef()
    const navigate = useNavigate();

    useEffect(() => {
        nameField.current.focus()
    },[]);

    const handleOnCancel = useCallback( () => navigate('/'), [navigate]);

    const handleSubmit = async (event) => {
        setLoading(true);
        event.preventDefault();
        setGameSummaries([])
        console.log(searchString)
        if (searchString.length > 4 && /^\d+$/.test(searchString)) {
            const response = await axios.get(`${BACKEND_URL}/bgg_data_search/?bgg_game_id=${searchString}&game_type=boardgame,boardgameexpansion`)
            setGameSummaries([response.data])
            console.log(response.data)
        } else {
            const response = await axios.get(`${BACKEND_URL}/bgg_name_search/?game_name=${searchString}&game_type=boardgame`)
            const games = response.data.sort((a, b) => (a.year_published > b.year_published) ? -1 : 1)
            setGameSummaries(games)
            console.log(games)
        }
        setLoading(false);
    };
    return (
        <div className="pt-10 flex-row">
            <div>
                <form onSubmit={handleSubmit}>
                    <input
                        className="px-3 py-3 placeholder-blueGray-300 text-blueGray-600 relative bg-white rounded text-sm border border-blueGray-300 outline-none focus:outline-none focus:ring"
                        type="text"
                        placeholder="game name"
                        value={searchString}
                        onChange={event => setSearchString(event.target.value)}
                        required
                        ref={nameField}/>
                    <button className="bg-lightBlue-500 text-lightBlue active:bg-lightBlue-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" >
                        Search
                    </button>
                    <button className="bg-lightBlue-500 text-lightBlue active:bg-lightBlue-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                        onClick={handleOnCancel}>
                        Cancel
                    </button>
                </form>
            </div>            
            {
            loading ? 
            <div className="text-center m-10">
                <Spinner/>
            </div>
            :
            <GameSummaryList
                gameSummaries={gameSummaries}
                gameSummaryHandler={setGameSummaries}
                searchStringHandler={setSearchString}/>
            }
        </div>

    );
}


const GameSummaryList = (props) => {
    const {games, addGame} = useContext(GameContext);
    let ids = games.map(g => g.bgg_id)

    const gameSummaryClickHandler = (game) => {
        addGame(game)
        props.gameSummaryHandler([])
        props.searchStringHandler("")
    }

    return (
        <div>
            {props.gameSummaries.map(summary => <GameSummary gameData={summary}
                                                             key={summary.bgg_id}
                                                             onClick={gameSummaryClickHandler}
                                                             disabled={ids.includes(parseInt(summary.bgg_id))}
            />)}
        </div>
    )
}


const GameSummary = (props) => {

    const gameClicked = () => {
        console.log("DISABLED? " + props.disabled)
        if (!props.disabled){
            props.onClick(props.gameData)
        }
    }
    return (

        <article className={`flex items-start space-x-6 p-6 hover:bg-blue-500 hover:ring-blue-500 hover:shadow-md group ${props.disabled ? "grayscale" : ""}`}
                 key={props.gameData.bgg_id} onClick={ gameClicked} >
            <img src={props.gameData.image} alt="" width="120" height="178"
                 className="flex-none rounded-md bg-gray-100"/>
            <div className="min-w-0 relative flex-auto">
                <h2 className="font-semibold text-gray-900 truncate pr-20 group-hover:text-white">{props.gameData.name}</h2>
                <dl className="mt-2 flex flex-wrap text-sm leading-6 font-medium group-hover:text-white">
                    <div className="absolute top-0 right-0 flex items-center space-x-1">
                        <dt className="text-sky-500">
                            <span className="sr-only">Rating</span>
                            <svg width="16" height="20" fill="currentColor">
                                <path
                                    d="M7.05 3.691c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.372 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.539 1.118l-2.8-2.034a1 1 0 00-1.176 0l-2.8 2.034c-.783.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.363-1.118L.98 9.483c-.784-.57-.381-1.81.587-1.81H5.03a1 1 0 00.95-.69L7.05 3.69z"/>
                            </svg>
                        </dt>
                        <dd>{props.gameData.rating_avg}</dd>
                    </div>
                    <div>
                        <dt className="sr-only">Rating</dt>
                        <dd className="px-1.5 ring-1 ring-gray-200 group-hover:ring-white-200 group-hover:text-white rounded">{props.gameData.year_published}</dd>
                    </div>
                    <div className="ml-2 group-hover:text-white">
                        <dt className="sr-only">Publisher</dt>
                        <dd>{props.gameData.publisher}</dd>
                    </div>
                    <div className="group-hover:text-white">
                        <dt className="sr-only">Players</dt>
                        <dd className="flex items-center">
                            <svg width="2" height="2" fill="currentColor" className="mx-2 text-gray-300"
                                 aria-hidden="true">
                                <circle cx="1" cy="1" r="1"/>
                            </svg>
                            {props.gameData.min_players}-{props.gameData.max_players} Players
                        </dd>
                    </div>
                    <div className="group-hover:text-white">
                        <dt className="sr-only">Playtime</dt>
                        <dd className="flex items-center">
                            <svg width="2" height="2" fill="currentColor" className="mx-2 text-gray-300"
                                 aria-hidden="true">
                                <circle cx="1" cy="1" r="1"/>
                            </svg>
                            {props.gameData.playtime} min
                        </dd>
                    </div>
                    <div className="flex-none w-full mt-2 font-normal">
                        <dt className="sr-only">Cast</dt>
                        <dd className="text-gray-400 group-hover:text-blue-200">{props.gameData.families?.join(', ')}</dd>
                    </div>
                </dl>
            </div>
        </article>

    );
}


export default BGGSearch;