import { useCallback, useEffect } from "react";

const ProgressModal = props =>{


    const {show, onComplete, body, percentage} = props;

    useEffect( () => {
        if (percentage === 100){
            onComplete();
        }
    }, [percentage, onComplete])

    if (!show){
        return null;
    }

    return (
        <div id="popup-modal" tabindex="-1" class=" overflow-y-auto overflow-x-hidden fixed top-0 right-0 bottom-0  bg-gray-700/90 left-0 z-50 md:inset-0 h-modal md:h-full flex items-center justify-center">
            <div class="relative p-4 w-full max-w-md h-full md:h-auto" onClick={e => e.stopPropagation()}>
                <div class="relative bg-white rounded-lg shadow dark:bg-gray-700">                    
                    <div class="p-6 text-center"> 
                    {body}
                        <div class="w-full bg-gray-200 rounded-full mt-2">
                            <div class="bg-blue-600 text-xs font-medium text-blue-100 text-center p-0.5 leading-none rounded-l-full" style={ {width: percentage+ '%'} }> {percentage}%</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ProgressModal