import React, { useContext } from "react";
import {TagContext} from "../context/tagContext";

const Filters = (props) => {


    const {tags, setTags} = useContext(TagContext)

    const removeFilter = tagName =>{
        console.log("removing filter " + tagName)
        setTags(tags.filter(f => f.name !== tagName))
        console.log("Filters", JSON.stringify(tags))
    }

    return (
        <div className="flex pl-5 pt-9" >
            {tags?.map( (tag) => (
                <span className="inline-block bg-cyan-600 font-medium rounded-full px-5 py-1 text-xs text-gray-100 mb-2 mr-3" key={tag.name}  onClick={()=>removeFilter(tag.name)} >
                    {tag.type} - {tag.name}
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 mx-2 inline-block" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z" />
                    </svg>
                </span>
            ))}
        </div>
    )
}
export default Filters
