import {useContext, useEffect, useState} from "react";
import {GameContext} from "../context/gameContext";
import {useParams, useNavigate} from "react-router-dom";
import {decode} from 'html-entities'
import Spinner from "./Spinner";
import ConfirmationModal from "./ConfirmationModal";
import {BadgeCheckIcon} from '@heroicons/react/solid'

const GameDetails = (props) => {

    const { removeGame, fetchGame, fetchExpansion, activeGame, refreshMetadata, toggledPlayed, toggledOwned, loading} = useContext(GameContext);
    const {bgg_id} = useParams()
    const [baseGame, setBaseGame] = useState({})
    const [isExpansion, setIsExpansion] = useState(false);
    const [showConfirm, setShowConfirm] = useState(false)
    let navigate = useNavigate();   
    const [hasPromos, setHasPromos] = useState(false)
    const [showPromos, setShowPromos] = useState(false)
    const [filteredExpansions, setFilteredExpansions] = useState([])
    


    useEffect( () =>{
        fetchGame(bgg_id)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [bgg_id])

    useEffect( () =>{
        console.log("Active Game: ", activeGame)
    }, [activeGame])

    const getFormattedDate = (dateStr) => {
        const date = new Date(dateStr);
        console.log(dateStr)
        return date.toLocaleString("en-US", {timeZone: "America/Denver", month: "short", day: "numeric", year: "numeric", hour: "numeric", minute: "numeric"});
      }

    useEffect( () =>{
        console.log("Updating filteredExpansions: " + showPromos)
        const promoRegex = /promo[s]?\b/mi
        if(activeGame.expansions){
            if (!showPromos){
                
                const x = [].concat(activeGame.expansions).filter(expansion => {
                    console.log("Checking expansion: " + expansion.name)
                    const isPromo = promoRegex.test(expansion.name) || promoRegex.test(expansion.description)
                    console.log("Is promo: " + isPromo)
                    return !isPromo
                })

                console.log("Filtered Expansions: " + x)
                setFilteredExpansions(x)
                    
            }else{
                console.log("Not using filtered Expansions", activeGame.expansions)
                setFilteredExpansions([].concat(activeGame.expansions))
            }
        }else{
            console.log("No Expansions, using empty array")
            setFilteredExpansions([])
        }
        console.log("Filtered expansions: " , filteredExpansions)
    },[activeGame, showPromos]);





    useEffect( () => {
        const promoRegex = /\bpromo\b/m
        setHasPromos(activeGame.expansions !== undefined  && activeGame.expansions != null && activeGame.expansions.some(expansion => promoRegex.test(expansion.name) || promoRegex.test(expansion.description)))
    }, [activeGame]);

    const refreshClicked = (event) => {
        console.log(`Refreshing Metadata for ${bgg_id}`)
        setShowConfirm(false)
        refreshMetadata(parseInt(bgg_id))
    }

    const handleMarkPlayedButton = () => {
        console.log(`Marking ${bgg_id} as played`)
        toggledPlayed(parseInt(bgg_id))
        
    }

    const handleMarkOwnedButton = () => {
        console.log(`Marking ${bgg_id} as owned`)
        toggledOwned(parseInt(activeGame.bgg_id))
    }

    const handleDeleteButton = () => {
        console.log(`Marking ${bgg_id} as delted`)
        removeGame(parseInt(bgg_id))
        navigate("/")        
    }

    const expansionClicked = (expansionId) => {
        setBaseGame ({"name": activeGame.name, "bgg_id": activeGame.bgg_id})
        fetchExpansion(expansionId)
    }

    const backToBaseGameClicked = () => {
        fetchGame(baseGame.bgg_id)
        setBaseGame({})
    }

    const backToLibraryClicked = () => {
        setBaseGame({})
        navigate("/")        
    }

    useEffect(() => {        
        setIsExpansion( !isEmpty(baseGame));
        
    }, [baseGame]);

    

    const isEmpty = obj => JSON.stringify(obj) === '{}';

    return (
        <div className="bg-gray-800  relative h-screen pt-8">
            <ConfirmationModal 
                body={`Are you sure you want to refresh the metatadata for ${activeGame.name}`}
                show={showConfirm} 
                onCancel={()=> setShowConfirm(false)} 
                onConfirm={()=>refreshClicked()}/>
            {loading ? 
                <div className="flex h-screen">
                    <div className="m-auto">
                        <Spinner/>
                    </div>
                </div>
            :
            <div className="bg-gray-800 flex relative items-center overflow-hidden">
                <div className="container mx-auto px-6 flex relative py-2">
                    <div className="sm:w-2/3 lg:w-2/5 flex flex-col relative">
                        {isExpansion && <span className="text-sm sm:text-base text-white pt-5 flex flex-row cursor-pointer" onClick={backToBaseGameClicked}> 
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                                <path strokeLinecap="round" strokeLinejoin="round" d="M7 16l-4-4m0 0l4-4m-4 4h18" />
                            </svg>Back to {baseGame.name}
                        </span>
                        }
                        {!isExpansion && <span className="text-sm sm:text-base text-white pt-5 flex flex-row cursor-pointer" onClick={backToLibraryClicked}> 
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                                <path strokeLinecap="round" strokeLinejoin="round" d="M7 16l-4-4m0 0l4-4m-4 4h18" />
                            </svg>Back to Library
                        </span>
                        }
                        <h1 className="font-bebas-neue uppercase text-6xl sm:text-6xl font-black flex flex-col leading-none text-white mt-2">
                            {activeGame.name}
                        </h1>
                        <p className="text-sm sm:text-base text-white pt-5" >{decode(activeGame.description)}</p>
                        <div className={isExpansion || activeGame.expansions === undefined || activeGame.expansions == null || activeGame.expansions.length === 0 ? "hidden" : ""}>
                            {hasPromos
                                ? <div className="flex flex-rows">
                                    <h2 className="font-bebas-neue uppercase text-2xl font-black align-middle flex mt-10 text-white" > Expansions </h2>
                                    <h3 className="font-bebas-neue uppercase font-black cursor-pointer text-xs  max-h-fit  mt-12 ml-2 text-white" onClick={()=>setShowPromos(!showPromos)}> - Promos {showPromos ? " Shown" : " Hidden"}</h3>
                                  </div>
                                : <h2 className="font-bebas-neue uppercase text-2xl font-black flex mt-10 leading-none text-white items-center"> Expansions</h2>
                            }
                            {filteredExpansions.map((expansion, index) => (
                            <div key={index} className="m-5 text-white p-1 flex flex-row cursor-pointer" onClick={() => expansionClicked(expansion.bgg_id)}>
                                <div className={`bg-center flex-none ${!expansion.owned ? "grayscale" : ""}`}
                                    style={{
                                        backgroundImage: `url("${expansion.header_image != null? expansion.header_image : expansion.image}")`,
                                        backgroundSize: 'cover',
                                        width: 200 ,
                                        height: 200
                                    }}>&nbsp;
                                    {expansion.played && <BadgeCheckIcon className="text-white h-20 w-20 absolute top-0 right-0"/>}
                                    </div>
                                <div className="w-full ml-5">
                                    <h3 className="font-bebas-neue uppercase text-l font-black flex flex-col leading-none text-white">{expansion.name} ({expansion.year_published})</h3>
                                    <div>
                                        <div className="flex flex-row">
                                            <svg width="16" height="20" fill="currentColor" className="ml-2 mr-1 text-white" aria-hidden="true">
                                                <path d="M7.05 3.691c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.372 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.539 1.118l-2.8-2.034a1 1 0 00-1.176 0l-2.8 2.034c-.783.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.363-1.118L.98 9.483c-.784-.57-.381-1.81.587-1.81H5.03a1 1 0 00.95-.69L7.05 3.69z"/>
                                            </svg>
                                            {Math.round(expansion.rating_avg * 100) / 100 }
                                            <svg version="1.1" id="Layer_1" x="0px" y="0px" height="20" viewBox="0 0 375 375"  fill="currentColor" className="ml-2 mr-1 text-white">
                                                <path id="XMLID_511_" d="M360,172.5h-15v-55c0-3.979-1.58-7.794-4.394-10.607c-2.813-2.813-6.628-4.393-10.606-4.393h-70c-8.284,0-15,6.716-15,15v55H135v-55c0-8.284-6.717-15-15-15H50c-3.979,0-7.793,1.58-10.606,4.393C36.58,109.706,35,113.521,35,117.5v55H15c-8.284,0-15,6.715-15,15c0,8.283,6.716,15,15,15h20v55c0,8.283,6.716,15,15,15h70c3.978,0,7.793-1.58,10.606-4.393c2.813-2.813,4.394-6.629,4.394-10.607v-55h110v55c0,3.978,1.58,7.794,4.394,10.607c2.813,2.813,6.628,4.393,10.606,4.393h70c8.283,0,15-6.717,15-15v-55h15c8.283,0,15-6.717,15-15C375,179.215,368.283,172.5,360,172.5z"/>
                                            </svg>
                                            {Math.round(expansion.weight_avg * 100) / 100 }
                                        </div>
                                        <div className="max-h-52 overflow-hidden">                                                                                            
                                            {decode(expansion.description)}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                        </div>    
                    </div>                           


                    <div className="hidden sm:block sm:w-1/3 lg:w-3/5 relative">
                        <div className="place-content-center px-5 text-white">
                            <div className="bg-center"
                                 style={{
                                     backgroundImage: `url("${activeGame.header_image != null? activeGame.header_image : activeGame.image}")`,
                                     backgroundSize: 'cover',
                                     width: 600 ,
                                     height: 600
                                 }}>
                                 {activeGame.played && <BadgeCheckIcon className="text-white h-32 w-32 absolute top-0 right-96"/>}
                            <div>
                            
                        </div>
                            </div>
                                 
                            <div className="flex flex-row">
                                <div className="w-96">
                                    <div className="mt-5">
                                        <h4 className="font-bebas-neue uppercase text-xl font-black">Details</h4>
                                    </div>
                                    <div>
                                        Year Published: {activeGame.year_published}
                                    </div>
                                    <div>
                                        Played: {activeGame.played ? 'Yes' : 'No'}
                                    </div>
                                    <div>

                                    </div>
                                    <div>
                                        Player Count: {activeGame.min_players}-{activeGame.max_players}
                                    </div>
                                    <div>
                                        Playtime: {activeGame.playtime} minutes {(activeGame.min_playtime !== activeGame.playtime || activeGame.max_playtime !== activeGame.playtime) ?` (${activeGame.min_playtime}-${activeGame.max_playtime})`:""}
                                    </div>
                                    <div>
                                        Rating: {activeGame.rating_avg} ({activeGame.rating_votes?.toLocaleString()} votes)
                                    </div>
                                    <div>
                                        Rating BAvg: {activeGame.rating_bavg}
                                    </div>
                                    <div>
                                        Rating Standard Deviation: {activeGame.rating_stdev}
                                    </div>
                                    <div>
                                        Weight: {activeGame.weight_avg} ({activeGame.weight_votes?.toLocaleString()} votes)
                                    </div>
                                    <div>
                                        Coop: {activeGame.is_cooperative ? 'Yes' : 'No'}<br/>
                                        Legacy: {activeGame.is_legacy ? 'Yes' : 'No'}<br/>
                                        Campaign: {activeGame.is_campaign ? 'Yes' : 'No'}<br/>
                                        18xx: {activeGame.is_18xx ? 'Yes' : 'No'}
                                    </div>
                                    <div>
                                        Designer: {activeGame.designer}
                                    </div>
                                    <div>
                                        Publisher: {activeGame.publisher}
                                    </div>
                                    <div className="pt-3 ">
                                        <h4 className="font-bebas-neue uppercase text-xl font-black">Mechanics</h4> {activeGame.mechanics?.map(( mechanic, index) => (<div key={index}>{mechanic}</div>))}
                                    </div>
                                    <div className="pt-3">
                                        <h4 className="font-bebas-neue uppercase text-xl font-black">Families</h4> {activeGame.families?.map(( family, index) => (<div key={index}>{family}</div>))}
                                    </div>                            
                                    <div className="pt-3">
                                        <h4 className="font-bebas-neue uppercase text-xl font-black">Categories</h4> {activeGame.categories?.map(( category, index) => (<div key={index}>{category}</div>))}
                                    </div>
                                </div>
                                <div className="flex flex-col h-40 mt-5">
                                    {isExpansion && <button onClick={handleMarkOwnedButton} className="border-2 bg-cyan-600 text-gray-200 w-40  h-10 rounded font-semibold">{ (activeGame.owned) ? "Mark as Unowned": "Mark as Owned"}</button>}
                                    {activeGame.owned && <button onClick={handleMarkPlayedButton} className="border-2 bg-cyan-600 text-gray-200 w-40 mt-2 h-10 rounded font-semibold">{ (activeGame.played) ? "Mark as Unplayed": "Mark as Played"}</button>}                                    
                                    {!isExpansion &&<button onClick={handleDeleteButton} className="border-2 bg-red-900 text-gray-200 w-40  mt-2 h-10 rounded font-semibold">Remove Game</button> }
                                    {!isExpansion && <p className="text-gray-600 mt-2 cursor-pointer" onClick={()=>setShowConfirm(true)}>Last Updated:<br/>{getFormattedDate(activeGame.update_time)}</p> }
                                    <a href={activeGame.bgg_url} className="text-gray-600 mt-2 cursor-pointer" target="_blank" rel="noreferrer">View on BGG</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            }         
            
        </div>      
)
}

export default GameDetails